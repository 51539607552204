import { useApolloClient } from '@apollo/react-hooks'
import { Auth as AmplifyAuth, Hub } from 'aws-amplify'
import PropTypes from 'prop-types'
import React, { createContext, useEffect, useReducer, useState } from 'react'

import actions from './actions'

const initialState = {
  isLoading: false,
  user: null,
  isGuest: true,
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.RESET_USER_DATA:
      return {
        ...state,
        user: null,
        isGuest: true,
      }
    case actions.FETCH_INIT:
      return {
        ...state,
        isLoading: true,
      }
    case actions.UPDATE_STATE:
    case actions.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: { ...initialState.status },
        ...action.payload,
      }
    default:
      return state
  }
}

const Context = createContext(null)

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [token, setToken] = useState(null)
  const [forcePasswordChange, setForcePasswordChange] = useState(false)
  const client = useApolloClient()

  const fetchParkHolidaysUser = async () => {
    dispatch({ type: 'FETCH_INIT' })
    return AmplifyAuth.currentAuthenticatedUser()
      .then(user => {
        // Here we'll massage the data into a format that works
        // well for application use, and spreads non-custom claim
        // data for future attribute mapping
        const {
          signInUserSession: {
            idToken: {
              payload: { custom, ...spreadPayload },
            },
          },
        } = user
        const newUser = {
          ...spreadPayload,
          ...JSON.parse(atob(custom)),
        }

        dispatch({
          type: actions.FETCH_SUCCESS,
          payload: { user: newUser, isGuest: false },
        })
      })
      .catch(error => ({ ok: false, message: error.message || error }))
  }

  const signIn = async (emailAddress, password) =>
    AmplifyAuth.signIn(emailAddress, password)
      .then(cognitoUser => {
        const newPasswordRequired = cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED'
        setForcePasswordChange(newPasswordRequired)

        const { signInUserSession } = cognitoUser
        const jwtToken = signInUserSession ? signInUserSession.idToken.jwtToken : null
        setToken(jwtToken)

        return {
          ok: true,
          data: cognitoUser,
          newPasswordRequired
        }
      })
      .catch(error => ({ ok: false, message: error.message || error }))

  const signOut = async () =>
    AmplifyAuth.signOut()
      .then(data => {
        setForcePasswordChange(false)
        return { ok: true, data }
      })
      .catch(error => ({ ok: false, message: error.message || error }))

  const signUp = async (emailAddress, password) =>

    AmplifyAuth.signUp({
      username: emailAddress,
      password,
      attributes: {
        email: emailAddress, // optional
        'custom:origin_domain': window.location.hostname,
        // other custom attributes
      },
      validationData: [], // optional
    })
      .then(data => ({ ok: true, data }))
      .catch(error => ({ ok: false, message: error.message || error }))

  const forgotPassword = async emailAddress =>
    AmplifyAuth.forgotPassword(emailAddress)
      .then(data => ({ ok: true, data }))
      .catch(error => ({ ok: false, message: error.message }))

  const resetPassword = async (username, code, newPassword) =>
    AmplifyAuth.forgotPasswordSubmit(username, code, newPassword)
      .then(data => ({ ok: true, data }))
      .catch(error => ({ ok: false, message: error.message }))

  const completeNewPassword = async (cognitoUser, newPassword) => {
        const { requiredAttributes } = cognitoUser.challengeParam;
        return await AmplifyAuth.completeNewPassword(
          cognitoUser,
          newPassword,
          requiredAttributes
        ).then(user => {
          const { signInUserSession } = user
          const jwtToken = signInUserSession ? signInUserSession.idToken.jwtToken : null
          setToken(jwtToken)

          return {
            ok: true,
            user,
          }
        }).catch(error => ({ ok: false, message: error.message || error }));
      }

  const changePassword = async (currentPassword, newPassword) =>
    AmplifyAuth.currentAuthenticatedUser()
      .then(user =>
        AmplifyAuth.changePassword(user, currentPassword, newPassword),
      )
      .then(data => {
        setForcePasswordChange(false)
        return { ok: true, data }
      })
      .catch(error => ({ ok: false, message: error.message || error }))

  const verifySession = async () => {
    AmplifyAuth.currentSession()
      .then(response => {
        const newPasswordRequired = response.challengeName === 'NEW_PASSWORD_REQUIRED'
        setForcePasswordChange(newPasswordRequired)

        const newToken = response.idToken.jwtToken
        if (newToken !== token) setToken(newToken)
      })
      .catch(error => {
        if (error === 'No current user' && state.user) signOut()
      })
  }

  const clearCookies = () => {
    const cookies = document.cookie
    cookies.split('; ').forEach(cookie => {
      document.cookie = `${cookie}=;expires=${new Date(0).toUTCString()}`
    })
  }

  /** USE EFFECTS */
  useEffect(() => {
    // Add event listener
    Hub.listen('auth', data => {
      const { payload } = data
      switch (payload.event) {
        case 'signIn':
          setForcePasswordChange(false)
          fetchParkHolidaysUser()
          client.resetStore()
          break
        case 'signOut':
          dispatch({ type: actions.RESET_USER_DATA })
          clearCookies()
          setToken(null)
          client.clearStore()
          localStorage.clear()
          break
        case 'tokenChanged':
          setToken(payload.data)
          break
        default:
          break
      }
    })

    return () => Hub.remove('auth')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const tokenCookie = (document.cookie.split('; ').find(cookie => cookie.includes('idToken')) || '').split('=')

    let newToken = null
    if (tokenCookie.length > 0) newToken = tokenCookie[1]

    if (newToken && newToken !== token) setToken(newToken)
    else if (newToken && newToken === token) fetchParkHolidaysUser()
    else signOut()
  }, [token])

  // Create new object that contains state and all the methods
  const newState = {
    ...state,
    forcePasswordChange,
    signIn,
    signOut,
    signUp,
    forgotPassword,
    resetPassword,
    completeNewPassword,
    changePassword,
    verifySession,
  }

  return <Context.Provider value={{ ...newState }}>{children}</Context.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export default Context
